import React from 'react';
import uxUiIcon from '@images/network-software-engineering/experience_card_uxUi.svg';

export const uxUi = {
  mainSection: {
    icon: uxUiIcon,
    elements: [
      {
        type: 'text',
        items: [
          <>
            According to our analysis, 92% of network apps do not meet solid UX criteria in terms of ease of use, look
            and feel, components and accessibility. This led us to create the UX score, which we can use to quickly but
            thoroughly rate almost every network application using these four criteria. Based on the score, we prepare a
            plan for improving your network application’s UX.
          </>,
        ],
      },
    ],
  },
  howWeHelpSection: {
    elements: [
      {
        type: 'list',
        items: [
          <>
            Analyze business and end-user needs specific for your network application, even those generating mountains
            of data
          </>,
          <>Create an information architecture for information-heavy environments</>,
          <>Research ideas and provide solution benchmarks</>,
          <>Redesign the UI according to modern usability standards</>,
          <>Ensure proper heavy data visualization</>,
          <>Conduct usability tests on end-users to iron out wrinkles</>,
          <>Build an interactive clickable UX prototype to demonstrate the product idea and show the navigation flow</>,
          <>Build a graphical user interface</>,
        ],
      },
    ],

    relatedResources: [
      {
        type: 'Case study',
        items: [
          {
            title: 'Create UX & UI of a portal for monitoring data visualization',
            link: '/case-studies/ux-ui-portal-for-monitoring-data-visualization/',
          },
          {
            title: 'Redesign an outdated UI for network management application',
            link: '/case-study/redesigning-outdated-ui-for-network-management-application/',
          },
          {
            title: 'Building a UX prototype of a network monitoring platform',
            link: '/case-study/building-ux-prototype-of-network-monitoring-platform/',
          },
        ],
      },
    ],
  },
};
