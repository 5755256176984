import React from 'react';
import classNames from 'classnames';

import { Button } from '@commons/button/Button';
import { StandardSection } from '@commons/standard-section/StandardSection';
import { ListRender } from '@commons/list-render/ListRender';

import * as styles from './tile-section.module.scss';

const TilesSection = ({ id, title, className, payload }) => {
  const { relatedResources } = payload.resources;
  const { elements: mainElements, icon } = payload.mainSection;
  const { tiles } = payload.tilesSection;

  const tilesSection = tiles.map(({ title, index, items }) => (
    <div key={index} className={styles.mainWrapperTilesSection}>
      <h3 key={title} className={styles.mainTitleTilesSection}>
        {title}
      </h3>
      <ListRender.Ul withArrows>
        {items.map(({ row, i }) => (
          <ListRender.Li key={i} blue>
            {row}
          </ListRender.Li>
        ))}
      </ListRender.Ul>
    </div>
  ));

  const main = mainElements.map(({ type, description }, elementIndex) => (
    <section key={elementIndex} className="spe-ne-content-section">
      {type === 'text' ? <p>{description}</p> : <div>{description}</div>}
    </section>
  ));

  const resources = (
    <div className="related-section">
      <h4>Related resources</h4>
      {relatedResources.map(({ type, items }) => (
        <div key={type} className="related-resource">
          <span>{type}</span>
          {items.map(({ link, title }) => (
            <Button key={link} href={link} label={title} />
          ))}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <StandardSection icon={icon} title={title} className={classNames(styles.section, 'spe-ne-main')}>
        <span id={id} />
        <p>{main}</p>
      </StandardSection>
      <StandardSection className={classNames(className, 'spe-ne-how-we-help')}>
        <div className={styles.tilesContainer}>
          <div>{tilesSection}</div>
          <div> {resources}</div>
        </div>
      </StandardSection>
    </>
  );
};

export default TilesSection;
