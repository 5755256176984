import React from 'react';

import { softwareIntegration } from './payload';
import { ProductSection } from '../../spe-ne';

export const SoftwareIntegrationSection = () => (
  <ProductSection
    id="nse-software-integration-section"
    title="Software integration"
    payload={softwareIntegration}
    background="black"
    arrowType="lime-arrow"
  />
);
