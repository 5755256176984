import React from 'react';

import { ProductSection } from '../../spe-ne';
import { pocMvp } from './payload';

export const PocMvpSection = () => (
  <ProductSection
    id="nse-poc-mvp-section"
    title="PoC / MVP development / UX prototype"
    payload={pocMvp}
    background="gray"
    buttonBelowLabel="Find out more"
    buttonBelowHref="/services/mvp-development/"
  />
);
