import React from 'react';
import PropTypes from 'prop-types';

import { Link } from '@commons/link/Link';

import arrow from './arrow.svg';

import * as styles from './experience-card.module.scss';

export const ExperienceCard = ({ icon, title, to }) => (
  <div className={styles.card}>
    <div>
      <div className={styles.icon}>
        <img loading="lazy" src={icon} alt={title} />
      </div>
      <h3>
        <strong>{title}</strong>
      </h3>
    </div>
    <Link href={to} className={styles.link}>
      <img loading="lazy" src={arrow} alt="link" />
    </Link>
  </div>
);

ExperienceCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
