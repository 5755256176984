import React from 'react';

import { experience } from './payload';
import { ExperienceSection as SPEExperienceSection } from '../../spe-ne';

export const ExperienceSection = () => (
  <SPEExperienceSection
    desc="Since 2011, CodiLime has been helping networking hardware and software providers, startups and telcos build and enhance their products. We cover every step of the software development process from UX prototype to MVP to the release of the final product,  providing expertise in UX design, frontend and backend development, and automation."
    payload={experience}
    id="nse-experience-section"
  />
);
