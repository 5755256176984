import React from 'react';

import { fullstackDev } from './payload';
import { ProductSection } from '../../spe-ne';

export const FullstackDevSection = () => (
  <ProductSection
    id="nse-fullstack-dev-section"
    title="Full stack development"
    payload={fullstackDev}
    background="black"
    arrowType="lime-arrow"
  />
);
