import React from 'react';
import classNames from 'classnames';

import { Button } from '@commons/button/Button';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { ListRender } from '@commons/list-render/ListRender';

import './product-section.scss';
import './product-section.mobile.scss';
import './custom-spe-ne-pages.scss';
import './spe-ne-scroll-wrapper.scss';

import * as styles from './product-section.module.scss';

const backgroundClassMapper = {
  white: styles.whiteBg,
  black: styles.blackBg,
  gray: styles.grayBg,
};

export const ProductSection = ({
  payload,
  id,
  title,
  className,
  arrowType,
  sectionAnchorClassName = '',
  background = 'white',
  children,
  buttonBelowLabel = '',
  buttonBelowHref = '',
}) => {
  const bgClass = backgroundClassMapper[background];

  const { elements: mainElements, icon } = payload.mainSection;
  const { elements: howWeHelpElements, relatedResources } = payload.howWeHelpSection;

  const main = mainElements.map(({ type, items, description }, elementIndex) => {
    if (type === 'text') {
      return (
        <div className="spe-ne-content-section" key={elementIndex}>
          {items.map((singleParagraph, paragraphIndex) =>
            singleParagraph?.props?.children ? (
              <p key={paragraphIndex}>{singleParagraph}</p>
            ) : (
              <br key={paragraphIndex} />
            ),
          )}
        </div>
      );
    }

    return (
      <section key={elementIndex} className="spe-ne-content-section">
        {description && <p>{description}</p>}
        <ListRender.Ul key={elementIndex}>
          {items.map((listItem, listItemIndex) => {
            const { listType, item } = listItem;
            const isBlueArrow = listType === 'blue-arrow' || arrowType === 'blue-arrow';
            const isLimeArrow = listType === 'lime-arrow' || arrowType === 'lime-arrow';
            return (
              <ListRender.Li
                nested={listType === 'nested'}
                arrow={isBlueArrow || isLimeArrow}
                blue={isBlueArrow}
                lime={isLimeArrow}
                key={listItemIndex}
              >
                {item ? item : listItem}
              </ListRender.Li>
            );
          })}
        </ListRender.Ul>
      </section>
    );
  });

  const howWeHelp = howWeHelpElements.map(
    ({ type, description, items, icon, title, parentClassName, titleParagraph }, elementIndex) => {
      if (type === 'text') {
        return (
          <div className={classNames('spe-ne-content-section', parentClassName)} key={elementIndex}>
            <p>{titleParagraph}</p>
          </div>
        );
      } else if (type === 'list') {
        return (
          <div key={elementIndex}>
            {title && (
              <h2 className="spe-ne-how-we-help-subheader">
                {icon && <img loading="lazy" src={icon} alt={title} />} {title}
              </h2>
            )}
            {description && <p>{description}</p>}
            <ListRender.Ul key={elementIndex}>
              {items.map((listItem, listItemIndex) => {
                const { listType, item } = listItem;
                const isBlueArrow = listType === 'blue-arrow' || arrowType === 'blue-arrow';
                const isLimeArrow = listType === 'lime-arrow' || arrowType === 'lime-arrow';
                return (
                  <ListRender.Li
                    nested={listType === 'nested'}
                    arrow={isBlueArrow || isLimeArrow}
                    blue={isBlueArrow}
                    lime={isLimeArrow}
                    key={listItemIndex}
                  >
                    {item ? item : listItem}
                  </ListRender.Li>
                );
              })}
              {children}
            </ListRender.Ul>
          </div>
        );
      }
    },
  );

  const resources =
    relatedResources && relatedResources.length ? (
      <div className="related-section">
        <h4>Related resources</h4>
        {relatedResources.map(({ type, items }) => (
          <div key={type} className="related-resource">
            <span>{type}</span>
            {items.map(({ link, title }) => (
              <Button key={link} href={link} label={title} />
            ))}
          </div>
        ))}
      </div>
    ) : (
      <div />
    );

  return (
    <>
      <StandardSection
        icon={icon}
        title={title}
        className={classNames(className, bgClass, 'spe-ne-main')}
        classNames={{ innerWrapper: styles.innerWrapper }}
      >
        <span id={id} className={sectionAnchorClassName} />
        {main}
        {buttonBelowLabel && <Button label={buttonBelowLabel} href={buttonBelowHref} className={styles.buttonBelow} />}
      </StandardSection>
      <StandardSection title="How we help" className={classNames(className, bgClass, 'spe-ne-how-we-help')}>
        <div className={styles.container}>
          <div> {howWeHelp}</div>
          <div>{resources}</div>
        </div>
      </StandardSection>
    </>
  );
};
