import React from 'react';

import { safeMap } from '@src/utils/mapper';

import './ease-in-logos.scss';

const easeInConfig = {
  type: 'slide-right',
  duration: 500,
  easing: 'ease',
  delay: 200,
  interval: 100,
};

const SingleLogo = ({ src, alt, customClass = '', ...rest }) => (
  <div className="single_logo" {...rest}>
    <img loading="lazy" className={customClass} src={src} alt={alt} height="35px" />
  </div>
);

export const EaseInLogos = ({ logos, config = {}, interval }) => {
  const animationConfig = { ...easeInConfig, ...config };
  const createLogoCallback = (logo, index) => (
    <SingleLogo
      key={index}
      {...logo}
      data-sal={animationConfig.type}
      data-sal-duration={animationConfig.duration}
      data-sal-easing={animationConfig.easing}
      data-sal-delay={animationConfig.delay + (interval || animationConfig.interval) * index}
    />
  );
  return <div className="logos_container">{safeMap(logos, createLogoCallback)}</div>;
};
