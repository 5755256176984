import React from 'react';

import useSiteMetadata from '@hooks/useSiteMetadata';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import { Header } from '@commons/header/Header';
import { TechnologyStackSection } from '@commons/technology-stack-section/TechnologyStackSection';

import { SPENEScrollWrapper } from '@pages-impl/spe-ne/spe-ne-scroll-wrapper';
import { logos } from '@pages-impl/network-software-engineering-services/payload';
import {
  ExperienceSection,
  FullstackDevSection,
  CiCdSection,
  SoftwareIntegrationSection,
  HardwareIntegrationSection,
  TestAutomationSection,
  PocMvpSection,
  UxUiSection,
} from '@pages-impl/network-software-engineering-services';

import featuredImage from '@images/header-images/featured_image_og_codilime_NSE.jpg';

export default function networkSoftwareEngineering(props) {
  const { siteURL } = useSiteMetadata();

  return (
    <Layout {...props} oldPage>
      <SEO
        title="Network Software Engineering Company - CodiLime"
        description="We provide software engineering services: full stack development, software and hardware integration, test automation, UX & UI. See how we help."
        featuredImage={siteURL + featuredImage}
      />
      <Header
        aboveTitle="Services"
        title="Network software engineering"
        descriptionText="Building future-proof networking software"
        id="software_engineering_header"
        src="codilime_software_engineering_bg.jpg"
      />
      <SPENEScrollWrapper>
        <ExperienceSection />
        <FullstackDevSection />
        <CiCdSection />
        <SoftwareIntegrationSection />
        <HardwareIntegrationSection />
        <TestAutomationSection />
        <PocMvpSection />
        <UxUiSection />
      </SPENEScrollWrapper>
      <TechnologyStackSection logos={logos} linkToTechnologies />
    </Layout>
  );
}
