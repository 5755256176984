import React from 'react';

import fullstackDevIcon from '@images/network-software-engineering/experience_card_fullstackDev.svg';

export const fullstackDev = {
  mainSection: {
    icon: fullstackDevIcon,
    elements: [
      {
        type: 'text',
        items: [
          <>
            If you are a startup, the CodiLime team can help you transform your idea into a final product ready to be
            launched on the market. If you are an enterprise, our engineers, project managers and solution architects
            will help you enhance your product regardless of its complexity level.
          </>,
        ],
      },
    ],
  },
  howWeHelpSection: {
    elements: [
      {
        type: 'list',
        items: [
          {
            item: <>Design or enhance a system’s architecture</>,
          },
          {
            item: <>Validate a potential product or solution by building a Proof of Concept (PoC)</>,
          },
          {
            item: (
              <>Design and deliver a novel solution that spans different layers: software, networking and hardware</>
            ),
          },
          {
            item: <>Deliver a custom-tailored orchestration solution</>,
          },
          {
            item: (
              <>
                Help improve non-functional characteristics for the system (performance, backups, monitoring, security,
                scalability, recoverability, reliability, observability)
              </>
            ),
          },
          {
            item: <>Provide full stack development of cloud-native apps:</>,
          },
          {
            listType: 'nested',
            item: <>UX and UI</>,
          },
          {
            listType: 'nested',
            item: <>CI/CD custom builds</>,
          },
          {
            listType: 'nested',
            item: <>Automated tests and QA</>,
          },
        ],
      },
    ],
    relatedResources: [
      {
        type: 'Case study',
        items: [
          {
            title: 'Building a microservices security platform',
            link: '/case-studies/',
          },
          {
            title: 'An automatic data center and low-level network infrastructure management solution',
            link: '/case-studies/',
          },
          {
            title: 'Create UX & UI of a portal for monitoring data visualization',
            link: '/case-studies/ux-ui-portal-for-monitoring-data-visualization/',
          },
        ],
      },
    ],
  },
};
