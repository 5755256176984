import React from 'react';
import { paths } from '@src/constants/pathsEnum';
import pocMvpIcon from '@images/network-software-engineering/experience_card_pocMvp.svg';

export const pocMvp = {
  mainSection: {
    icon: pocMvpIcon,
    elements: [
      {
        type: 'text',
        items: [
          <>
            If you are a startup, an MVP will definitely help you verify your business concept. A Minimum Viable Product
            (MVP) is a version of a software product that has enough functionalities to satisfy the needs of early
            adopters and at the same time convince investors to invest in it. An MVP is a real-life demonstration that
            your idea works and can be developed further—into a fully functional product.
          </>,
        ],
      },
      {
        type: 'text',
        items: [
          <>
            If you need funding right now but don’t have time to develop an MVP, a clickable UX prototype is another
            option. This will be the first showcase of your product, its functionalities and a navigation flow. Armed
            with a physical prototype, it will be easier to check if your ideas work and convince others to back them.{' '}
          </>,
        ],
      },
      {
        type: 'text',
        items: [
          <>
            If you are an enterprise, we can build a PoC for you. A Proof of Concept (PoC) is built to demonstrate that
            a product or feature you have designed will work. You can use PoC to verify the validity of your design idea
            or assumption. If it works, it can be developed further.
          </>,
        ],
      },
    ],
  },
  howWeHelpSection: {
    elements: [
      {
        type: 'text',
        parentClassName: 'paragraphs-for-nse',
        titleParagraph:
          'CodiLime has expertise in every development stage: UX design, frontend, backend and final release:',
      },
      {
        type: 'list',
        listType: 'blue-arrow',
        items: [
          {
            listType: 'blue-arrow',
            item: (
              <>
                Provide cross-functional teams made up of UX designers, front- and backend developers, product owners
                and scrum masters
              </>
            ),
          },
          {
            listType: 'blue-arrow',
            item: <>Select the technology stack that matches your business goals</>,
          },
          {
            listType: 'blue-arrow',
            item: <>Build CI/CD to enable fast releases and automation of your product</>,
          },
          {
            listType: 'blue-arrow',
            item: <>Develop, test and release the final product either in the cloud or on the client’s premises</>,
          },
          {
            listType: 'blue-arrow',
            item: <>Design and build a graphical user interface to ensure best user experience</>,
          },
          {
            listType: 'blue-arrow',
            item: <>Design and build a PoC to test your ideas</>,
          },
        ],
      },
    ],

    relatedResources: [
      {
        type: 'Case study',
        items: [
          {
            title: 'Using ONOS SDN controller with P4-programmable smartNICs to offload VNFs',
            link: paths.RESOURCES.CI_CD_PIPELINE,
          },
          {
            title: 'Building an UX prototype of a network monitoring platform',
            link: '/case-study/building-ux-prototype-of-network-monitoring-platform/',
          },
        ],
      },
      {
        type: 'White paper',
        items: [
          {
            title: 'MVP for startups',
            link: '/services/software-development-for-startups/',
          },
        ],
      },
    ],
  },
};
