import React from 'react';
import { isFunction } from 'lodash';
import { StandardSection } from '@commons/standard-section/StandardSection';

import { ExperienceCard } from '@commons/experience-card/ExperienceCard';

import * as styles from './experience-section.module.scss';

export const ExperienceSection = ({ id, payload, desc }) => (
  <StandardSection
    title="Our experience in a nutshell"
    className={styles.section}
    classNames={{ innerWrapper: styles.innerWrapper }}
  >
    <span id={id} />
    {isFunction(desc) ? desc() : <p>{desc}</p>}
    <div className={styles.container}>
      {payload.map(({ icon, title, sectionId }) => (
        <ExperienceCard title={title} icon={icon} key={title} to={`#${sectionId ? sectionId : title.toLowerCase()}`} />
      ))}
    </div>
  </StandardSection>
);
