import ciCd from '@images/network-software-engineering/experience_card_ciCd.svg';
import fullstackDev from '@images/network-software-engineering/experience_card_fullstackDev.svg';
import hardwareIntegration from '@images/network-software-engineering/experience_card_hardwareIntegration.svg';
import pocMvp from '@images/network-software-engineering/experience_card_pocMvp.svg';
import softwareIntegration from '@images/network-software-engineering/experience_card_softwareIntegration.svg';
import testAutomation from '@images/network-software-engineering/experience_card_testAutomation.svg';
import uxUi from '@images/network-software-engineering/experience_card_uxUi.svg';

export const experience = [
  {
    title: 'Full stack development',
    icon: fullstackDev,
    sectionId: 'nse-fullstack-dev-section',
  },
  {
    title: 'CI/CD',
    icon: ciCd,
    sectionId: 'nse-ci-cd-section',
  },
  {
    title: 'Software integration',
    icon: softwareIntegration,
    sectionId: 'nse-software-integration-section',
  },
  {
    title: 'Hardware integration',
    icon: hardwareIntegration,
    sectionId: 'nse-hardware-integration-section',
  },
  {
    title: 'Test automation',
    icon: testAutomation,
    sectionId: 'nse-test-automation-section',
  },
  {
    title: 'PoC / MVP development',
    icon: pocMvp,
    sectionId: 'nse-poc-mvp-section',
  },
  {
    title: 'UX & UI',
    icon: uxUi,
    sectionId: 'nse-ui-ux-section',
  },
];
