import React from 'react';

import { ProductSection } from '../../spe-ne';
import { testAutomation } from '@pages-impl/network-software-engineering-services/test-automation/payload';

export const TestAutomationSection = () => (
  <ProductSection
    id="nse-test-automation-section"
    title="Test Automation"
    payload={testAutomation}
    background="black"
    arrowType="lime-arrow"
  />
);
