import React from 'react';

import softwareIntegrationIcon from '@images/network-software-engineering/experience_card_softwareIntegration.svg';

export const softwareIntegration = {
  mainSection: {
    icon: softwareIntegrationIcon,
    elements: [
      {
        type: 'text',
        items: [
          <>
            We integrate various types of software into a unified single system regardless of their complexity. Cloud,
            containers, IaaC, SD-WAN, orchestration—these are our bread and butter.
          </>,
        ],
      },
    ],
  },
  howWeHelpSection: {
    elements: [
      {
        type: 'list',
        items: [
          {
            item: <>Migrate a system or component to a cloud</>,
          },
          {
            item: <>Migrate a solution to be Infrastructure-as-a-Code (IaaC)-compatible</>,
          },
          {
            item: (
              <>
                Integrate an existing SD-WAN solution with a client’s system via API—either for the software solution or
                hardware integration
              </>
            ),
          },
          {
            item: <>Integrate your software with:</>,
          },
          {
            listType: 'nested',
            item: <>Orchestrators or virtualization platforms (K8s/OpenShift and OpenStack)</>,
          },
          {
            listType: 'nested',
            item: <>Public, private or hybrid clouds</>,
          },
          {
            listType: 'nested',
            item: <>Kubernetes platform (such as integration via Operator Framework)</>,
          },
          {
            listType: 'nested',
            item: <>SR-IOV clusters</>,
          },
          {
            listType: 'nested',
            item: <>Operating systems (such as Linux, Windows Server)</>,
          },
          {
            listType: 'nested',
            item: (
              <>
                Networking devices via network APIs (YANG/Netconf, etc.) or operating system drivers and user-space
                solutions (such as DPDK)
              </>
            ),
          },
          {
            listType: 'nested',
            item: <>Monitoring tools (Grafana, Prometheus, Zabbix)</>,
          },
          {
            listType: 'nested',
            item: <>Other third-party solutions</>,
          },
        ],
      },
    ],

    relatedResources: [
      {
        type: 'Case study',
        items: [
          {
            title: 'Integration of Tungsten Fabric SDN controller with Red Hat Openshift Kubernetes-based platform',
            link: '/case-study/integration-of-tungsten-fabric-sdn-controller-with-red-hat-openshift-kubernetes-based-platform/',
          },
          {
            title: 'Integration of Tungsten Fabric SDN, OVS, and SR-IOV compute nodes with an ML2 OpenStack mechanism',
            link: '/case-study/integration-of-tungsten-fabric-sdn-ovs-and-sr-iov-compute-nodes-with-ml2-openstack-mechanism/',
          },
          {
            title: 'Designing an open-source CI tool to simplify GitOps with Spinnaker',
            link: '/case-study/designing-open-source-ci-tool-to-simplify-gitops-with-spinnaker/',
          },
        ],
      },
    ],
  },
};
