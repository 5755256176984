import React from 'react';

import hardwareIntegrationIcon from '@images/network-software-engineering/experience_card_hardwareIntegration.svg';

export const hardwareIntegration = {
  mainSection: {
    icon: hardwareIntegrationIcon,
    elements: [
      {
        type: 'text',
        description: (
          <>
            For data center hardware providers and large telcos, we provide hardware integration services including
            DPDK, smartNIC, P-4 programmable hardware and FPGA to help customize hardware and software to precise
            requirements.
          </>
        ),
      },
    ],
  },
  tilesSection: {
    tiles: [
      {
        title: <>DPDK</>,
        items: [
          {
            row: <>Develop DPDK Poll Mode Drivers for various hardware platforms</>,
          },
          { row: <>Integrate DPDK drivers with a number of applications</> },
          {
            row: (
              <>
                Contribute to DPDK framework by fixing issues and extending the interfaces according to new PMD driver
                requirements
              </>
            ),
          },
          {
            row: <>Develop performance tests to show that switching to DPDK improves performance</>,
          },
        ],
      },
      {
        title: <>SmartNIC users and manufacturers</>,
        items: [
          {
            row: <>Implement a desired network function using P4 (if P4 language is supported by the given smartNIC)</>,
          },
          {
            row: (
              <>
                Write eBPF programs to be executed within the host system or SmartNIC kernel for improving performance
                and maintenance costs of the solution
              </>
            ),
          },
          {
            row: (
              <>
                Adapt the user’s existing Control/Management software (e.g. SDN controller) to control the given
                smartNIC at runtime
              </>
            ),
          },
          { row: <>Develop firmware for smartNIC</> },
          { row: <>Develop drivers (DPDK PMD or kernel module)</> },
          {
            row: <>QA—automated performance test environment (performance and functional tests)</>,
          },
        ],
      },
      {
        title: <>P4</>,
        items: [
          {
            row: (
              <>Consult the use case with the client focusing on all possible traffic flows to be served within it</>
            ),
          },
          {
            row: (
              <>
                Verify which P4 architecture model (PSA, V1Model, PISA or other) is supported by a given target (switch,
                smartNIC) and prepare a P4 implementation for this precise model
              </>
            ),
          },
          {
            row: (
              <>Design the entire dataplane processing pipeline (match-action tables for each control block) using P4</>
            ),
          },
          {
            row: <>Create a P4 code compilation and build it using the tools provided by the target manufacturer</>,
          },
          { row: <>Conduct functional tests for a newly created dataplane</> },
          {
            row: (
              <>
                Adapt the client’s existing Control/Management software (e.g. SDN controller) to control the given
                P4-target at runtime
              </>
            ),
          },
        ],
      },
      {
        title: <>FPGA</>,
        items: [
          { row: <>IP Core integration and development (VHDL)</> },
          { row: <>HW simulation (e.g. using Active-HDL, ModelSim)</> },
          { row: <>Smoke tests executed directly on hardware</> },
          { row: <>API level tests of hardware designs</> },
          {
            row: (
              <>
                Building full, system-level, dynamic acceptance tests alongside a complete virtual environment
                reflecting end-user use cases
              </>
            ),
          },
          {
            row: (
              <>
                Building full CI/CD pipelines verifying the hardware design in a continuous mode with full visibility
                for developers, QA and managers (domain, software and hardware knowledge on board)
              </>
            ),
          },
        ],
      },
    ],
  },
  resources: {
    relatedResources: [
      {
        type: 'Case study',
        items: [
          {
            title: 'Using ONOS SDN controller to control a heterogeneous data plane',
            link: '/case-study/using-onos-sdn-controller-to-control-heterogeneous-data-plane/',
          },
          {
            title: 'Using ONOS SDN controller with P4-programmable smartNICs to offload VNFs',
            link: '/case-study/using-onos-sdn-controller-with-p4-programmable-smartnics-to-offload-vnfs/',
          },
        ],
      },
    ],
  },
};
