import {
  c,
  java,
  docker,
  azure,
  openVSwitch,
  kubernetes,
  dpdk,
  aws,
  openStack,
  pytest,
  cypress,
  vueJS,
  robotFramework,
  zuul,
  spinnaker,
  redux,
  ibmCloud,
  bamboo,
  googleCloud,
  rust,
  circleCI,
  travisCI,
  django,
  jenkins,
  vmware,
  spring,
  angular,
  typeScript,
  javaScript,
  go,
  selenium,
  cPlusPlus,
  react,
  gitLab,
  python,
  intelFPGA,
} from '@images/logos-techstack';

export const logos = [
  pytest,
  aws,
  vmware,
  cypress,
  rust,
  python,
  intelFPGA,
  vueJS,
  robotFramework,
  zuul,
  spinnaker,
  cPlusPlus,
  openStack,
  redux,
  react,
  gitLab,
  c,
  azure,
  ibmCloud,
  googleCloud,
  bamboo,
  circleCI,
  kubernetes,
  travisCI,
  django,
  docker,
  jenkins,
  java,
  openVSwitch,
  spring,
  angular,
  typeScript,
  dpdk,
  javaScript,
  go,
  selenium,
];
