import React from 'react';

import testAutomationIcon from '@images/network-software-engineering/experience_card_testAutomation.svg';

export const testAutomation = {
  mainSection: {
    icon: testAutomationIcon,
    elements: [
      {
        type: 'text',
        items: [
          <>
            At CodiLime, we take on full responsibility for your product quality assurance processes, starting from
            Quality Assurance at the development stage up to end-user tests (including UX). In our testing solutions, we
            strive to use all the benefits of automation.
          </>,
        ],
      },
    ],
  },
  howWeHelpSection: {
    elements: [
      {
        type: 'list',
        items: [
          {
            item: (
              <>
                Build a comprehensive testing infrastructure (physical and virtual testbeds) with a modern IaC approach
              </>
            ),
          },
          {
            item: (
              <>
                Define and implement a quality assurance plan/testing plan including regression, long run, performance,
                E2E tests regardless of the complexity level of the SUT (System Under Test)
              </>
            ),
          },
          {
            item: (
              <>Implement, customize or reuse an existing testing framework according to the client’s project needs</>
            ),
          },
          {
            item: <>Maintain, refactor and customize existing testing solutions</>,
          },
          {
            item: (
              <>
                Implement custom tools needed to test your specific hardware and software (network traffic generators,
                embedded systems tools for hardware checks, test data generators, etc.)
              </>
            ),
          },
          {
            item: <>Integrate your test framework with any CI/CD pipeline</>,
          },
        ],
      },
    ],

    relatedResources: [
      {
        type: 'Case study',
        items: [
          {
            title: 'Building CI/CD and testing for a full-stack monitoring and alerting service',
            link: '/case-studies/',
          },
          {
            title: 'Creating an NFV telco cloud based on OpenStack and Tungsten Fabric',
            link: '/case-study/creating-nfv-telco-cloud-based-on-openstack-and-tungsten-fabric/',
          },
        ],
      },
    ],
  },
};
