import React, { useEffect, useState, useMemo } from 'react';
import cx from 'classnames';
import { paths } from '@src/constants/pathsEnum';

import { Button } from '@commons/button/Button';
import { EaseInLogos } from '@commons/ease-in-logos/EaseInLogos';
import { StandardSection } from '@commons/standard-section/StandardSection';

import * as styles from './technology-stack.module.scss';

export const easeInConfig = {
  delay: 100,
  interval: 50,
};

export const easeInConfigMore = {
  type: 'none',
};

export const TechnologyStackSection = ({
  description = '',
  split = 1,
  id = 'our_technology_stack',
  title = 'Our technology stack',
  className,
  classNames = {},
  logos = [],
  linkToTechnologies = false,
}) => {
  const logosParts = useMemo(() => splitArray([...logos], Math.floor(logos.length / split)), [split, logos]);

  const [shownPart, setShownParts] = useState(0);
  const [parts, setParts] = useState((logosParts || []).map((logos, index) => mapper(logos, index)));

  const showMore = () => {
    setShownParts(shownPart + 1);
  };

  useEffect(() => {
    setParts((logosParts || []).map((logos, index) => mapper(logos, index)));
  }, [shownPart]);

  return (
    <StandardSection
      id={id}
      title={title}
      description={description}
      className={cx(styles.section, className)}
      classNames={{ innerWrapper: styles.innerWrapper, ...classNames }}
    >
      {description}
      {parts}
      {shownPart < parts.length - 1 && <Button onClick={showMore} label="Load more" flexed />}
      {linkToTechnologies && (
        <Button label="See more tech stack" href={paths.TECHNOLOGIES} className={styles.buttonTechStack} />
      )}
    </StandardSection>
  );

  function mapper(logos, index, shown = shownPart) {
    return index <= shown ? (
      <EaseInLogos logos={logos} key={index} config={!index ? easeInConfig : easeInConfigMore} />
    ) : null;
  }

  function splitArray(logos, partLength = logos.length, result = []) {
    const resultLength = result.reduce((length, logosArr) => length + logosArr.length, 0);

    if (resultLength >= logos.length) {
      return result;
    }

    result.push(logos.slice(resultLength, resultLength + partLength));

    return splitArray(logos, partLength, result);
  }
};
